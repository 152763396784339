<script setup>
// import libraries
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'
import router from '@/router'
import SvgComponent from '@/components/SvgComponent.vue'
import ButtonComponent from '@/components/ButtonComponent.vue'
import SearchInputComponent from '@/components/form/SearchInputComponent.vue'
import CardComponent from '@/components/CardComponent.vue'
import { useScreenSizeBehaviours } from '@/composables/useScreenSizeBehaviours'

import { VOnboardingWrapper, useVOnboarding } from 'v-onboarding'
import 'v-onboarding/dist/style.css'

const wrapper = ref(null)
const { start } = useVOnboarding(wrapper)

// variables
const store = useStore()
const { isMobileSize } = useScreenSizeBehaviours()
// const autocompleteSuggestion = ref(null)


// ComputedVariables
const isSuper = computed(() => store.getters['user/isSuper'])
const isAuthenticated = computed(() => store.getters['user/getIsLoggedIn'])
const onboardingDone = computed(() => localStorage.getItem('onboarding-service') ?? false)
const isDeleteButtonEnabled = computed(() => store.getters["company/getSelectedServices"].length > 0)

const searchString = computed({
    get: () => store.getters["company/getActiveServicesSearchString"],
    set: (value) => store.dispatch("company/setActiveServicesSearchString", value)
})

const autocompleteSuggestion = computed(() => store.getters["company/getAutocompleteSuggestion"])

const steps = [
    { 
        attachTo: { element: '#add-service' }, 
        content: { 
            title: 'Adding a service',
            description: 'This is the first step to start selling! <br><br> Just click this button and follow the instructions.',
            html: true
        },
        options: {
            hideButtons: {
                exit: true
            },
        },
        on: {
            afterStep: function (options) {
                localStorage.setItem('onboarding-service', true)
            }
        }
    },
]

// Methods
function logout() {
    localStorage.setItem('user', '{}')
    router.push({ "name": "login" })
}

function onClickAd() {
    alert('Go to ad url')
}

function onClickChatIcon() {
    alert('Go to chat')
}

function onClickDeleteButton() {
    store.dispatch('event/showWarningModal', {
        title: 'Warning',
        htmlCopy: '<p>The selected services will be permanently deleted.<br><strong>Do you wish to proceed?</strong></p>',
        buttons: [
            {
                copy: "Yes",
                action: performDelete,
                htmlAttributes: {"color": "primary", "style": "min-width:84px;"},
            },
            {
                copy: "No",
                action: null,
                htmlAttributes: {"bordered": true, "class": "btn-decline", "style": "min-width:84px;"}
            }
        ],
        exitAction:null
    })
}

async function performDelete() {
    await store.dispatch('company/bulkUpdate', {
        ids: store.getters["company/getSelectedServices"],
        deleted: true
    })
    router.go(router.currentRoute)
}

function performSearch() {
    store.dispatch("company/applyFilter")
}

// Callbacks Lifecycle
onMounted(() => {
    if (!isAuthenticated.value) {
        router.push({
            "name": "login"
        })
    }

    if (!onboardingDone.value && !isMobileSize.value && !isSuper.value) {
        start()
    }
})
</script>

<template>
    <VOnboardingWrapper ref="wrapper" :steps="steps" />
    <div class="salutation">
        <h1>Services</h1>
        <p>Manage your services</p>
    </div>
    <div class="d-flex flex-wrap filters-wrapper">
        <SearchInputComponent
            v-model="searchString"
            :autocompleteSuggestion="autocompleteSuggestion"
            @performSearch="performSearch"
        />
        <div class="d-flex flex-wrap gap ml-auto" >
            <router-link to="/dashboard/services/new" custom v-slot="{ navigate }">
                <ButtonComponent id="add-service" color="primary" @click="navigate">ADD SERVICES</ButtonComponent>
            </router-link>
            <ButtonComponent :disabled="!isDeleteButtonEnabled" color="danger" @click="onClickDeleteButton()">DELETE</ButtonComponent>
            <!-- <ButtonComponent class="btn-dropdown d-flex align-items-center" bordered color="secondary">
                <SvgComponent name="IconFilter" />
                <span>FILTER</span>
                <SvgComponent name="IconArrowDown" />
            </ButtonComponent> -->
            <!-- <ButtonComponent class="btn-dropdown d-flex align-items-center" bordered color="secondary">
                <span>ORDER</span>
                <SvgComponent name="IconArrowDown" />
            </ButtonComponent> -->
        </div>
    </div>
    <div class="row d-flex direction-column">
        <div class="d-flex tabs-wrapper">
            <router-link to="/dashboard/services/preview" custom v-slot="{ isExactActive, navigate }"><ButtonComponent :color="isExactActive ? 'light' : 'light-primary'" @click="navigate" :class="{active: isExactActive}">ACTIVE</ButtonComponent></router-link>
            <router-link to="/dashboard/services/preview/inactive" custom v-slot="{ isExactActive, navigate }"><ButtonComponent :color="isExactActive ? 'light' : 'light-primary'" @click="navigate" :class="{active: isExactActive}">INACTIVE</ButtonComponent></router-link>
            <router-link to="/dashboard/services/preview/draft" custom v-slot="{ isExactActive, navigate }"><ButtonComponent :color="isExactActive ? 'light' : 'light-primary'" @click="navigate" :class="{active: isExactActive}">DRAFTS</ButtonComponent></router-link>
            <!-- <router-link to="/dashboard/services/preview/promoted" custom v-slot="{ isExactActive, navigate }"><ButtonComponent :color="isExactActive ? 'light' : 'light-primary'" @click="navigate" :class="{active: isExactActive}">PROMOTED</ButtonComponent></router-link> -->
        </div>
        <CardComponent>
            <router-view />
        </CardComponent>
    </div>
</template>

<style lang="scss" scoped>
.row {
    flex-direction: column;
    margin-bottom: 20px;

    &:not(.direction-column) {
        @media screen and (min-width: 1200px) {
            flex-direction: row;
        }
    }
}
.filters-wrapper {
    gap: 15px;
    align-items: center;
    margin-top: 56px;
    margin-bottom: 31px;
    justify-content: space-between;

    button {
        min-width: 115px;
        padding: 15.5px;
    }
    .btn-dropdown {
        border-color: var(--primary-icon-color-30);
        max-height: 47px;

        span {
            margin-left: 15.47px;
            margin-right: 11.12px;
        }
    }
}

.primary {
    font-size: 11px;
}

.tabs-wrapper {
    margin-bottom: -10px;
    gap: 2.1px;

    button {
        min-width: 102px;
        position: relative;
        padding-bottom: 25.5px;
        font-weight: 500;

        border-radius: 0;

        &:first-child {
            border-radius: 12px  0 0 0;
            box-shadow: -3px -3px 6px -3px rgba(0, 0, 0, 0.20);
        }

        &:last-child {
            border-radius: 0 12px 0 0;
        }

        &:not(.active):hover {
            background-color: var(--light-secondary-color);
        }

        &.active {
            z-index: 1;
            font-weight: 700;
        }
    }
}
</style>