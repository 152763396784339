import router from '@/router'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { paramsObjectToString } from '@/composables/helper'
import { decreaseDateByHours, localTimeDate } from '@/composables/dateFormat.js'
import { useI18n } from 'vue-i18n'

export function useService() {
    const store = useStore()
    const serviceSlug = router.currentRoute.value.params.slug
    const currentService = computed(() => store.getters['frontend/getCurrentService'](serviceSlug))
    
    store.dispatch('frontend/getServiceBySlug', serviceSlug)
    return { currentService }
}

export function formToQuery(serviceForm) {
    let queryObject = structuredClone(serviceForm)
    
    if(serviceForm.seats && serviceForm.seats.length) {
        for(let seat of serviceForm.seats) {
            queryObject[seat.name.toLowerCase()] = seat.qty
        }
    }

    delete queryObject.seats
    
    return queryObject
}

export function buildServiceRouteUrl(service, purchaseNotes) {
    let baseUrl = `/${service.service_slug}`
    if(!purchaseNotes) {
        return baseUrl
    }

    return `${baseUrl}?${paramsObjectToString(formToQuery(purchaseNotes))}`
}

export function calcPrice(service, purchaseNotes) {
    let response = 0
    if(purchaseNotes.seats) {
        let priceForSelectedSeats = 0
        for(let option of purchaseNotes.seats) {
            if(option.qty > 0 && option.value > 0) {
                priceForSelectedSeats += option.qty * option.value
            }
        }

        if(priceForSelectedSeats > 0) {
            response = priceForSelectedSeats
        }
    } else if(purchaseNotes.participants) {
        response = Number(service.value) * purchaseNotes.participants
    }

    return response
}

export function generateCancelationPolicy(service, purchaseNotes) {
    const { t } = useI18n()
    
    let response = t('site.cart.free_cancellation_not_available')

    const serviceAnnotations = typeof service.annotations === "string"
        ? JSON.parse(service.annotations) 
        : service.annotations

    if (serviceAnnotations?.free_cancellation > 0 && serviceAnnotations?.cancellation_policy > 0) {
        let freeCancelationDate = decreaseDateByHours(purchaseNotes.date, serviceAnnotations?.cancellation_policy)
        
        if (freeCancelationDate > new Date()) {
            response = t('site.cart.free_cancellation_before') + " " + localTimeDate(freeCancelationDate)
        }
    }

    return response
}

export function mapSeatsKeyVal(seats) {
    let responseObj = {}
    for(let seatOption of seats) {
        responseObj[seatOption.name] = seatOption.qty
    }
    return responseObj
}

export function mapPriceOptionsToSeats(priceOptions, curentSeats = null) {
    if (curentSeats) {
        curentSeats = mapSeatsKeyVal(curentSeats)
    }

    let options = []
    for (let priceOptionIndex in priceOptions) {
        let priceOption = priceOptions[priceOptionIndex]
        let optionToPush = {
            qty: (curentSeats && curentSeats[priceOption.priceType]) 
                ? curentSeats[priceOption.priceType] 
                : 0,
            value: Number(priceOption.value),
            name: priceOption.priceType === 'Other' ? priceOption.otherValue : priceOption.priceType,
            ages: priceOption?.ageStart ? `(${priceOption?.ageStart} - ${priceOption?.ageEnd})` : '',
        }

        options.push(optionToPush);
    }

    return options
}

export function mapGroupPriceOptionsToSeats(priceOptions, type, curentSeats = null) {
    if (curentSeats) {
        curentSeats = mapSeatsKeyVal(curentSeats)
    }

    const values = priceOptions.map(option => ({
        priceType: option.priceType,
        max: option.capacityMax,
        value: Number(option.value),
    }))

    // const optionName = type === 'GROUP_PRICE' ? 'Group' : 'Participants'
    const optionName = type === 'GROUP_PRICE' ? 'Participants' : 'Participants'
    const maxCapacity = curentSeats['Group'] ?? 1
    const currValue = values.find(value => value.max > maxCapacity)
    
    return [
        {
            qty: (curentSeats && curentSeats[optionName]) ? curentSeats[optionName] : 0,
            value: currValue.value,
            values: values,
            name: optionName,
        }
    ]
}