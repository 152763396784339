<script setup>
import { ref } from 'vue'
import ButtonComponent from '@/components/ButtonComponent.vue';
import SvgComponent from '@/components/SvgComponent.vue';

const props = defineProps({
    title: String,
    show: {type: Boolean, default: false },
    toggable: { type: Boolean, default: true}
})

const isOpen = ref(props.show)

function onClick() {
    isOpen.value = !isOpen.value
}
</script>

<template>
    <div class="wrapper">
        <header>
            <h1 class="toggle-title" @click="!isOpen ? onClick() : null">{{ title }}</h1>
            <ButtonComponent color="transparent" simple :size="{ width: '27px', height: '27px' }" @click="onClick" v-if="!isOpen && toggable">
                <SvgComponent name="IconArrowDown" />
            </ButtonComponent>
            <ButtonComponent class="btn-options" color="transparent" simple :size="{ width: '27px', height: '27px' }" v-else-if="false">
                <SvgComponent name="IconEllipsis" />
            </ButtonComponent>
        </header>
        <main v-if="isOpen || !toggable">
            <slot></slot>
            <ButtonComponent class="toggle-buttom" color="transparent" simple :size="{ width: '27px', height: '27px' }" @click="onClick" v-if="toggable">
                <SvgComponent name="IconArrowDown" />
            </ButtonComponent>
        </main>
    </div>
</template>

<style lang="scss" scoped>
.wrapper {
    border-radius: 12px;
    background: #FFF;
    min-height: 66px;
    filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.20));
    position: relative;
    width: 100%;

    .btn-options {
        margin-top: -20px;

        @media screen and (min-width: 768px) {
            margin-top: 0;
        }
    }

    .toggle-buttom {
        position: absolute;
        transform: rotate(180deg);
        right: 10px;
        bottom: 3.5px;
    }
    
    header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 66px;
        padding: 22px 17px;
        box-sizing: border-box;

        @media screen and (min-width: 768px) {
            padding: 25px 32px;
        }
        
        h1 {
            color: var(--secondary-text-color);
            font-family: Montserrat;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: uppercase;
        }
    }

    main {
        @media screen and (min-width: 768px) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}
</style>