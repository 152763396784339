import axios from "axios"
import ApiManager from '@/helpers/ApiManager.js'
axios.defaults.baseURL = process.env.VUE_APP_API_URL

const getDefaultState = () => {
    return {
        categories: [],
        fields: [],
        steps: [],
        errors: [],
        service: null
    }
}

const state = getDefaultState()

function testJSON(text) {
    if (typeof text !== "string") {
        return false;
    }
    try {
        let parsed = JSON.parse(text);
        return parsed;
    } catch (error) {
        return false;
    }
}

function jsonParseFields(data) {
    for (let category of data) {
        if (category.fields && typeof category.fields === "string") {
            category.fields = JSON.parse(category.fields)
        }
    }
    return data
}

function handleServiceDecode(data) {
    data.annotations = JSON.parse(data.annotations)
    for (let key in data.annotations) {
        if (key === 'calendar_price') {
            let testForValidJson = testJSON(data.annotations[key])
            if (!!testForValidJson) {
                data.annotations[key] = testForValidJson
            }
        }

        if (data.annotations[key] && Array.isArray(data.annotations[key])) {
            let j = 0
            for (let arrayItem of data.annotations[key]) {
                let testForValidJson = testJSON(arrayItem)
                if (!!testForValidJson) {
                    data.annotations[key][j] = testForValidJson
                }
                j++
            }
        }
    }

    data.languages = JSON.parse(data.languages)

    return data
}

const getters = {
    getIsLoaded: state => (state.steps && !!state.steps[0]) && (state.steps && !!state.fields[0]),
    getSteps: state => state.steps,
    getFields: state => state.fields,
    getFieldByStepId: (state) => (stepId) => {
        return state.fields
            .filter(field => field.form_step_id === stepId)
            .sort((a, b) => a.display_order - b.display_order)
    },
    getCategoryOptions: (state) => {
        let options = {}
        for (let cat of state.categories) {
            options[cat.category_name] = cat.category_name
        }
        
        return options
    },
    getFormErrors: (state) => state.errors,
    getService: (state) => state.service
}

const actions = {
    fetchFormCategories: async ({ commit }, formName) => {
        let getAttempt = await ApiManager.get(`/api/form-categories/${formName}`)

        if (getAttempt) {
            getAttempt.data = jsonParseFields(getAttempt.data)
            commit("SET_CATEGORIES", getAttempt.data)
            commit("SET_FIELDS", getAttempt.data[0].fields)
        }
    },

    fetchFormSteps: async ({ commit }, formName) => {
        let getAttempt = await ApiManager.get(`/api/form-steps/${formName}`)

        if (getAttempt) {
            commit("SET_STEPS", getAttempt.data);
        }
    },

    resetFieldsByCat: ({ state, commit }, catName) => {
        let catSearch = state.categories.find(category => category.category_name === catName)
        if (catSearch) {
            commit("SET_FIELDS", catSearch.fields) 
        } else {
            //TODO: fazer uma categoria com campos estecíficos para criação de categoria
            commit("SET_FIELDS", state.categories[0].fields)
        }
        
        commit("RESET_ERRORS")
    },

    submitForm: async ({ state, commit, rootGetters }, form) => {
        let formData = new FormData()
        for (const [key, value] of Object.entries(form.payload)) {
            if (key === 'calendar_price') {
                formData.append(key, JSON.stringify(value))
            } else if (typeof value === "object") {
                for (let item in value) {
                    if (typeof value[item] === "object" && !(value[item] instanceof File)) {
                        formData.append(key + "[]", JSON.stringify(value[item]))
                    } else {
                        formData.append(key + "[]", value[item])
                    }
                }
            } else {
                formData.append(key, value)
            }
        }
        let url = `api/form-submit/${form.name}`
        if (state.service && state.service.id && !form.duplicate) {
            url = `api/dashboard/services/${state.service.id}`
        }

        let postAttempt = await ApiManager.post(
            url,
            formData,
            {
                'Content-Type': 'multipart/form-data'
            }
        )

        if (postAttempt.data && postAttempt.data.status) {
            return true
        } else if (postAttempt.errors) {
            commit("SET_ERRORS", postAttempt.errors)
            return false
        }
    },

    deleteError: ({ commit }, fieldSlug) => {
        commit("RESET_FIELD_ERROR", fieldSlug)
    },

    resetErrors: ({ commit }) => {
        commit("RESET_ERRORS")
    },

    fetchService: async ({ commit }, id) => {
        let getAttempt = await ApiManager.get(`api/dashboard/services/${id}`);
        if (getAttempt && getAttempt.data && getAttempt.data.status) {
            getAttempt.data.content = handleServiceDecode(getAttempt.data.content)
            getAttempt.data.content.price = getAttempt.data.content.value
            commit("SET_SERVICE", getAttempt.data.content)
        }
    },

    resetState: ({ commit }) => {
        commit("RESET_STATE")
    }
}

const mutations = {
    SET_CATEGORIES: (state, categories) => {
        state.categories = categories
    },
    SET_FIELDS: (state, fields) => {
        state.fields = fields
    },
    SET_STEPS: (state, steps) => {
        state.steps = steps
    },
    SET_ERRORS: (state, errors) => {
        Object.entries(errors).map(error => {
            const [key, value]  = error
            const keyPieces     = key.split('.')

            if(keyPieces[1] && !isNaN(Number(keyPieces[1]))) {
                if(!errors[keyPieces[0]]) {
                    errors[keyPieces[0]] = []
                }
                errors[keyPieces[0]].push(String(value).replace(key, keyPieces[0]))
            }
        })
        state.errors = errors
    },

    RESET_ERRORS: (state) => {
        state.errors = {}
    },

    RESET_FIELD_ERROR: (state, fieldSlug) => {
        if (state.errors[fieldSlug]) {
            delete state.errors[fieldSlug]
        }
    },

    SET_SERVICE: (state, service) => {
        state.service = service
    },

    RESET_STATE (state) {
        Object.assign(state, getDefaultState())
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}