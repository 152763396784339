import { computed } from 'vue'
import axios from 'axios'
import store from '@/store'
import {useToast} from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'

const toast = useToast()

axios.defaults.headers.common["Accept"] = "application/json"

const isSuper = computed(() => store.getters['user/isSuper'])
const activeUser = computed(() => store.getters['superAdm/getActiveUser'])
const defaultLocale = computed(() => store.getters['user/getLocale'])

class ApiManager {

    setupTokenHeader() {
        if (store.getters['user/getIsLoggedIn']) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + store.getters['user/getApiKey']
        }
        
        axios.defaults.headers.common["Accept-Language"] = defaultLocale.value
    }

    getUrl(url) {
        const activeUserId = isSuper.value && activeUser.value.id !== -1 
            ? activeUser.value.id 
            : null

        let newUrl = url
        if (activeUserId) {
            newUrl += url.includes('?') ? '&' : '?'
            newUrl += `impersonate_id=${activeUserId}`
        }

        return newUrl
    }

    get(url, adicionalHeaders = null) {
        this.setupTokenHeader()

        return axios
            .get(this.getUrl(url), adicionalHeaders)
            .then(response => response)
            .catch(error => {
                console.error(error)
                // this.genericError()
                return false
            });
    }

    post(url, data, adicionalHeaders = null, errorCopy = null) {
        this.setupTokenHeader()

        if (isSuper.value && activeUser.value.id != -1 && activeUser.value.id !== undefined) {
            data['impersonate_id'] = activeUser.value.id

            if (adicionalHeaders) {
                data.append('impersonate_id', activeUser.value.id)
            }
        }
    
        return axios
            .post(url, data, adicionalHeaders)
            .then(response => response)
            .catch(error => {
                
                // to handle failed validations on the frontend,
                // we return the error messages of 422 Unprocessable Content or Unauthorized 403
                if (
                    error.response &&
                    error.response.status &&
                    (
                        [422,403].includes(error.response.status) && error.response.data
                    )
                ) {
                    return error.response.data
                } 
                    
                console.error(error)
                this.genericError(errorCopy)
                return false
            });
    }

    genericError(errorCopy = null) {
        let copy = errorCopy ? errorCopy : "Operation could not be completed. Please try again."
        toast.error(copy, { position: 'top-right'})
    }

}

export default new ApiManager()